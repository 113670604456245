import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
} from '@angular/core';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from '../../../../core/authentication/domain/entities/user.entity';
import {
	selectAuthSession,
	selectLoading,
} from '../../../../core/authentication/state/selectors/auth.selectors';
import {
	distinctUntilChanged,
	Observable,
	Subject,
	Subscription,
	takeUntil,
} from 'rxjs';
import { SpinnerModalComponent } from '../spinner-modal/spinner-modal.component';
import * as NotificationActions from '../../../../core/notification/state/actions/notification.actions';
import {
	selectAllNotifications,
	selectNotificationsLoading,
} from '../../../../core/notification/state/selectors/notification.selectors';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LogoutComponent } from '../../../components/logout/logout.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
	heroArrowRightStartOnRectangle,
	heroArrowsRightLeft,
	heroBell,
	heroChatBubbleLeftRight,
	heroCog8Tooth,
	heroCreditCard,
	heroCurrencyDollar,
	heroDocumentText,
	heroEnvelope,
	heroHome,
	heroInformationCircle,
	heroLanguage,
	heroMapPin,
	heroPhone,
	heroQuestionMarkCircle,
	heroUserGroup,
} from '@ng-icons/heroicons/outline';
import { ModalComponent } from '../modal/modal.component';
import { ButtonComponent } from '../button/button.component';
import { createFeedback } from '../../../../core/info/state/actions/info.actions';
import {
	FormBuilder,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import {
	selectInfosError,
	selectInfosLoading,
	selectInfoState,
} from '../../../../core/info/state/selectors/info.selectors';
import { TextareaComponent } from '../textarea/textarea.component';
import { StarRatingComponent } from '../star-rating/star-rating.component';
import { setDefaultLanguage } from '../../../../core/authentication/state/actions/auth.actions';
import { CommonModule } from '@angular/common';
import { selectUserImage } from '../../../../core/settings-management/state/settings.selectors';
import { getUserProfileImage } from '../../../../core/settings-management/state/settings.actions';
import { selectUnreadDiscussionCount } from '../../../../core/message-management/state/selectors/discussion.selectors';
import { getUnreadDiscussionCount } from '../../../../core/message-management/state/actions/discussion.actions';
import { ClickOutsideDirective } from '../../../../shared/directives/clickOutside/click-outside.directive';
import { BeneficiaryPermissions } from '../../../../shared/permissions/beneficiary.permissions';
import { TransferPermissions } from '../../../../shared/permissions/transfer.permissions';

@Component({
	selector: 'app-sidebar',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		NgIconComponent,
		TranslateModule,
		StarRatingComponent,
		LogoutComponent,
		SpinnerModalComponent,
		ModalComponent,
		TextareaComponent,
		ButtonComponent,
		ClickOutsideDirective,
	],
	providers: [
		provideIcons({
			heroHome,
			heroArrowsRightLeft,
			heroUserGroup,
			heroCreditCard,
			heroDocumentText,
			heroCurrencyDollar,
			heroEnvelope,
			heroBell,
			heroMapPin,
			heroPhone,
			heroCog8Tooth,
			heroQuestionMarkCircle,
			heroInformationCircle,
			heroArrowRightStartOnRectangle,
			heroLanguage,
			heroChatBubbleLeftRight,
		}),
	],
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.css',
})
export class SidebarComponent implements OnInit, OnDestroy {
	@Input() visible: boolean = false;
	numberDiscussions: number = 0;
	numberNotifications: number = 0;

	userRating: number = 0;

	activeLink: string = '/home';
	isLogoutModalOpen = false;

	@Output() clickOutside = new EventEmitter<void>();

	user: User | undefined;

	loading$: Observable<boolean>;
	loadingNotification$: Observable<boolean>;

	infosLoading$: Observable<boolean>;
	infosError$: Observable<any>;
	userProfileImage$: Observable<string> | null = null;
	userProfileImage: string | null = null;

	languages = [
		{
			value: 'en',
			label: 'SIDEBAR.LANGUAGES.ENGLISH',
		},
		{
			value: 'fr',
			label: 'SIDEBAR.LANGUAGES.FRENCH',
		},
	];

	menuItems = [
		{ name: 'SIDEBAR.HOME', icon: 'heroHome', link: '/home' },
		{ name: 'SIDEBAR.MY_ACCOUNT', icon: 'heroCreditCard', link: '/account' },
	];


	currentLang: string = '';

	createFeedbackFormGroup!: FormGroup;
	isModalOpen = false;
	sendForm = true;
	errorForm = false;
	successForm = false;
	rating: number = 0;

	private readonly subscriptions: Subscription[] = [];

	private readonly destroy$ = new Subject<void>();

	constructor(
		private readonly router: Router,
		private readonly store: Store,
		private readonly translate: TranslateService,
		private readonly fb: FormBuilder,
		readonly beneficiaryPermissions: BeneficiaryPermissions,
		readonly transferPermissions: TransferPermissions
	) {
		this.initForm();
		this.activeLink = this.router.url;
		this.loading$ = this.store.select(selectLoading);
		this.loadingNotification$ = this.store.select(selectNotificationsLoading);
		this.currentLang =
			this.translate.currentLang || this.translate.getDefaultLang();
		this.infosLoading$ = this.store.select(selectInfosLoading);
		this.infosError$ = this.store.select(selectInfosError);
		this.userProfileImage$ = this.store.select(selectUserImage);
	}

	setLanguage(event: Event): void {
		const selectedValue = (event.target as HTMLSelectElement).value;
		// Liste des langues supportées
		const supportedLangs = ['en', 'fr'];

		// Utilise la langue du navigateur si elle est supportée, sinon utilise l'anglais par défaut
		const defaultLang = supportedLangs.includes(selectedValue)
			? selectedValue
			: 'fr';

		this.translate.setDefaultLang(defaultLang); // Définit la langue par défaut
		this.translate.use(defaultLang); // Applique la langue courante
		localStorage.setItem('selectedLanguage', defaultLang); // Enregistrer la langue dans localstorage
		const lang = defaultLang === 'fr' ? 'French' : 'US-English';
		this.setAPILanguage(lang);
	}

	setAPILanguage(lang: string) {
		this.store.dispatch(
			setDefaultLanguage({
				data: {
					defaultLanguage: lang,
				},
			})
		);
	}

	initForm() {
		this.createFeedbackFormGroup = this.fb.group({
			description: ['', [Validators.required]],
			feedbackID: [''],
			improvement: [''],
			likeMost: [''],
			rating: ['', [Validators.required]],
			user_id: [''],
			userName: [''],
		});
	}

	ngOnInit() {
		this.subscriptions.push(
			this.loading$.subscribe(),
			this.loadingNotification$.subscribe()
		);
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.activeLink = this.router.url;
			}
		});

		this.store.select(selectAuthSession).subscribe((authSession) => {
			this.user = authSession?.user;
		});
		this.store.dispatch(NotificationActions.loadNotifications());

		this.store.select(selectAllNotifications).subscribe((notification) => {
			this.numberNotifications = Number(
				notification.unreadNotificationCount
			);
		});

		this.store.dispatch(getUnreadDiscussionCount());

		this.store.select(selectUnreadDiscussionCount).subscribe((count) => {
			this.numberDiscussions = Number(count);
		});

		this.infosLoading$.subscribe((loading) => {
			this.isLoading = loading;
		});
		this.infosError$.subscribe((error) => {
			if (error) {
				this.isLoading = false;
			}
		});

		this.userProfileImage$
			?.pipe(takeUntil(this.destroy$))
			.subscribe((imageBase64) => {
				if (imageBase64) {
					this.userProfileImage = `data:image/png;base64,${imageBase64}`;
				}
				else {
					this.store.dispatch(getUserProfileImage());
				}
			});

		this.handleInfoStateUpdate();

		this.transferPermissions.canCreateTransfer && this.menuItems.push({ 
			name: 'SIDEBAR.TRANSFER', icon: 'heroArrowsRightLeft',  link: '/transfer' });
		(this.beneficiaryPermissions.canCreatePayee || this.beneficiaryPermissions.canCreateCoporatePayee) && this.menuItems.push({
			name: 'SIDEBAR.BENEFICIARY', icon: 'heroUserGroup', link: '/beneficiary'});
		
		this.menuItems.push(
			{
				name: 'SIDEBAR.RIB',
				icon: 'heroDocumentText',
				link: '/account/rib',
			},
			{
				name: 'SIDEBAR.ACCOUNT_STATMENT',
				icon: 'heroDocumentText',
				link: '/account/statement',
			},
			{
				name: 'SIDEBAR.EXCHANGE_RATES',
				icon: 'heroCurrencyDollar',
				link: '/exchange-rates',
			},
			{ name: 'SIDEBAR.MESSAGE', icon: 'heroEnvelope', link: '/message' },
			{ name: 'SIDEBAR.NOTIFICATION', icon: 'heroBell', link: '/notification' },
			{ name: 'SIDEBAR.HOW_TO_FIND_US', icon: 'heroMapPin', link: '/find-us' },
			{ name: 'SIDEBAR.CONTACT_US', icon: 'heroPhone', link: '/contact-us' },
			{
				name: 'SIDEBAR.PRIVACY_POLICY',
				icon: 'heroPhone',
				link: '/privacy-policy',
			},
			// {
			// 	name: 'Note',
			// 	icon: 'heroChatBubbleLeftRight',
			// },
			{
				name: 'SIDEBAR.FREQUENTLY_ASKED_QUESTIONS',
				icon: 'heroQuestionMarkCircle',
				link: '/faq',
			},
			{ name: 'SIDEBAR.LANGUAGE', icon: 'heroLanguage', link: '#' },
			{ name: 'SIDEBAR.SETTINGS', icon: 'heroCog8Tooth', link: '/settings' },
		)
	}

	private handleInfoStateUpdate(): void {
		this.store
			.select(selectInfoState)
			.pipe(
				takeUntil(this.destroy$),
				distinctUntilChanged(
					(prev, curr) => prev.feedback === curr.feedback
				)
			)
			.subscribe((state) => {
				if (state.feedback.length) {
					this.sendForm = false;
					this.successForm = true;
				}
				if (state.error) {
					this.errorForm = true;
				}
			});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.destroy$.next();
		this.destroy$.complete();
	}

	setActiveLink(link: string): void {
		this.activeLink = link;
	}
	openModal() {
		this.isModalOpen = true;
	}

	closeModal() {
		this.isModalOpen = false;
	}

	handleKeyDown(event: KeyboardEvent, link: string) {
		if (event.key === 'Enter' || event.key === ' ') {
			this.setActiveLink(link);
		}
	}

	onClickOutside() {
		if (this.visible) {
			this.clickOutside.emit();
		}
	}

	openLogoutModal() {
		this.isLogoutModalOpen = true;
	}
	closeLogoutModal() {
		this.isLogoutModalOpen = false;
	}

	isLoading: boolean = false;

	createFeedback() {
		if (this.createFeedbackFormGroup.valid) {
			const data = {
				...this.createFeedbackFormGroup.value,
				improvement: this.createFeedbackFormGroup.value.rating,
				likeMost: this.createFeedbackFormGroup.value.rating,
			};
			this.store.dispatch(
				createFeedback({
					data,
				})
			);
		} else {
			this.markFormGroupTouched(this.createFeedbackFormGroup);
		}
	}

	private markFormGroupTouched(formGroup: FormGroup) {
		Object.values(formGroup.controls).forEach((control) => {
			control.markAsTouched();
			if (control instanceof FormGroup) {
				this.markFormGroupTouched(control);
			}
		});
	}

	private marktransferFormTouched(transferForm: FormGroup) {
		Object.values(transferForm.controls).forEach((control) => {
			control.markAsTouched();

			if (control instanceof FormGroup) {
				this.marktransferFormTouched(control);
			}
		});
	}

	setRating($event: any) {
		this.rating = $event;
		this.userRating = $event;
		this.createFeedbackFormGroup.get('rating')?.setValue($event);
		if ($event > 3) {
			this.createFeedbackFormGroup.get('description')?.clearValidators();
		} else {
			this.createFeedbackFormGroup
				.get('description')
				?.setValidators([Validators.required]);
		}
		this.createFeedbackFormGroup.get('description')?.updateValueAndValidity();
	}
}
